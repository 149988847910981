import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BreadcrumbsService } from '@suvo-bi-core';
import { IRoute } from '../interface/route.interface';
import { RoutesService } from '../service/routes.service';

@Injectable()
export class RouteResolver implements Resolve<IRoute> {
  constructor(
    private readonly routes: RoutesService,
    private readonly breadcrumb: BreadcrumbsService,
  ) {}

  async resolve(activatedRoute: ActivatedRouteSnapshot): Promise<IRoute> {
    const route = await this.routes.getOne('', activatedRoute.params['id']);
    this.breadcrumb.setDynamicNameSwap('routeID', route.oldRouteId);
    return route;
  }
}
