import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRoute } from '../../interface/route.interface';
import { RoutesService } from '../../service/routes.service';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { IError } from '@suvo-bi-core';

@Component({
  selector: 'app-route-edit-screen',
  templateUrl: './route-edit-screen.component.html',
  styleUrls: ['./route-edit-screen.component.scss'],
})
export class RouteEditScreenComponent implements OnInit, OnDestroy {
  @ViewChild('editComponent') editComponent: EditComponent;

  private readonly unsubscribe$ = new Subject<void>();
  readonly formGroup = new FormGroup({
    oldRouteId: new FormControl<string>('', { nonNullable: true }),
    ship: new FormControl<string>('', { nonNullable: true }),
    fromPort: new FormControl<string>('', { nonNullable: true }),
    toPort: new FormControl<string>('', { nonNullable: true }),
    regionName: new FormControl<string>('', { nonNullable: false }),
    regionCode: new FormControl<string>('', { nonNullable: false }),
    country: new FormControl<string>('', { nonNullable: true }),
    endDate: new FormControl<string>('', { nonNullable: true }),
    isInactive: new FormControl<boolean>(false, { nonNullable: true }),
    isEmbargoed: new FormControl(false, { nonNullable: true }),
  });
  route?: IRoute;

  serverErrors: Array<IError>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly routes: RoutesService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ data }: { data?: IRoute }) => {
        if ((this.route = data)) {
          this.formGroup.setValue({
            oldRouteId: data.oldRouteId,
            ship: data.ship,
            fromPort: data.fromPort,
            toPort: data.toPort,
            regionName: data.regionName ? data.regionName : '',
            regionCode: data.regionCode ? data.regionCode : '',
            country: data.country ? data.country : '',
            endDate: this.formatDate(data.endDate),
            isInactive: data.isInactive,
            isEmbargoed: data.isEmbargoed,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSave($event: IRoute) {
    let ed = new Date($event.endDate);
    let et = ed.getTime();

    if (isNaN(et)) {
      $event.endDate = null;
    }

    for (const property of ['ship']) {
      if ($event[property] instanceof Array) {
        $event[property] = $event[property][0];
      }
    }

    this.serverErrors = null;
    try {
      if (this.route?._id) {
        await this.routes.patchOne('', this.route._id, $event);
        this.editComponent.markAsUpdated();
      } else {
        const { _id } = await this.routes.postOne('', $event);
        await this.router.navigate(['..', _id], { relativeTo: this.activatedRoute });
      }
    } catch (e) {
      this.serverErrors = [e.error];
      this.editComponent.enable();
    }
  }

  formatDate(date: Date | string | null): string {
    if (date === null) return '';
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
}
