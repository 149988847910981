<app-header />

<mat-card>
  <mat-card-header>
    <mat-card-title>Route List</mat-card-title>
    <div class="buttons">
      <button mat-flat-button routerLink="create" [checkPermission]="['route', 'edit']">
        Create
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <app-routes-table tableAlias="routes" />
  </mat-card-content>
</mat-card>
