<app-header />

<mat-card>
  <mat-card-header>
    <mat-card-title>Route Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-edit
      [formGroup]="formGroup"
      permission="route.edit"
      (save)="onSave($event)"
      [errors]="serverErrors"
      #editComponent
    >
      <div class="input-group">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput formControlName="oldRouteId" required />
        </mat-form-field>

        <suvo-bi-reference-autocomplete-selector
          referenceLabelProperty="label"
          entityDefinitionAlias="ships"
          formControlName="ship"
        />
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>From Port</mat-label>
          <input matInput formControlName="fromPort" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>To Port</mat-label>
          <input matInput formControlName="toPort" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Region Name</mat-label>
          <input matInput formControlName="regionName" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Region Code</mat-label>
          <input matInput formControlName="regionCode" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Country</mat-label>
          <input matInput formControlName="country" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>End date</mat-label>
          <input matInput formControlName="endDate" type="date" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-checkbox formControlName="isInactive">Inactive?</mat-checkbox>
      </div>
      <div class="input-group">
        <mat-checkbox formControlName="isEmbargoed">Embargoed?</mat-checkbox>
      </div>
    </app-edit>
  </mat-card-content>
</mat-card>
