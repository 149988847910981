<table
  mat-table
  [dataSource]="dataSource$"
  matSort
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
>
  <ng-container matColumnDef="oldRouteId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let item">{{ item.oldRouteId }}</td>
  </ng-container>

  <ng-container matColumnDef="oldRouteComplexId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Complex ID</th>
    <td mat-cell *matCellDef="let item">{{ item.oldRouteComplexId }}</td>
  </ng-container>

  <ng-container matColumnDef="fromPort">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>From Port</th>
    <td mat-cell *matCellDef="let item">{{ item.fromPort }}</td>
  </ng-container>

  <ng-container matColumnDef="toPort">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>To Port</th>
    <td mat-cell *matCellDef="let item">{{ item.toPort }}</td>
  </ng-container>

  <ng-container matColumnDef="ship">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Ship</th>
    <td mat-cell *matCellDef="let item">{{ item.ship?.name }}</td>
  </ng-container>

  <ng-container matColumnDef="isInactive">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Inactive?</th>
    <td mat-cell *matCellDef="let item">{{ item.isInactive }}</td>
  </ng-container>

  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Updated</th>
    <td mat-cell *matCellDef="let item">{{ item.updatedAt | date: 'medium' : 'UTC' }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr
    mat-row
    class="row clickable"
    [class.hidden]="isLoading"
    *matRowDef="let row; columns: headings"
    [routerLink]="row._id"
  ></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

<ng-container *ngIf="isLoading">
  <suvo-bi-skeleton-table-rows
    [rowCount]="pageSize"
    [columnWidths]="[10, 10, 10, 10, 10, 10, 10, 10, 10, 10]"
  ></suvo-bi-skeleton-table-rows>
</ng-container>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
></mat-paginator>
