import { Component, Input } from '@angular/core';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { IRoute } from '../../interface/route.interface';
import { RoutesService } from '../../service/routes.service';

@Component({
  selector: 'app-routes-table',
  templateUrl: './routes-table.component.html',
  styleUrls: ['./routes-table.component.scss'],
})
export class RoutesTableComponent extends TableComponent<IRoute, string> {
  readonly headings = [
    'oldRouteComplexId',
    'oldRouteId',
    'fromPort',
    'toPort',
    'ship',
    'isInactive',
    'updatedAt',
  ];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  constructor(
    sessionService: SessionService,
    private readonly routesService: RoutesService,
  ) {
    super(sessionService);
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<IRoute>> {
    return this.routesService.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    });
  }
}
