import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteTableScreenComponent } from './screens/table/route-table-screen.component';
import { RouteEditScreenComponent } from './screens/edit/route-edit-screen.component';
import { RouteResolver } from './resolver/route.resolver';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Routes' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RouteTableScreenComponent,
      },
      {
        path: 'create',
        pathMatch: 'full',
        data: { breadcrumb: 'Create' },
        component: RouteEditScreenComponent,
      },
      {
        path: ':id',
        data: { breadcrumbDynamic: 'routeID' },
        resolve: { data: RouteResolver },
        component: RouteEditScreenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
